import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CounterService {

    public shoppingCartCounter: BehaviorSubject<number> = new BehaviorSubject(0);

}
