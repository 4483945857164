import { DataOption } from 'src/app/shared/implements/data-option';

export const ALLOWED_MAX_FILE_SIZE = 100;
export const APP_PROMOTION_BASKET = 'pdags_promotion_basket';
export const APP_SHOPPING_CART = 'pdags_app_shopping_cart';
export const APP_CURRENT_VENDORS = 'pdags_vendors';
export const AUTH = 'pdags_auth';
export const AUTH_CURRENT_USER = 'current_user';
export const AUTH_REDIRECT = 'pdags_auth_redirect';
export const AUTH_REMEMBER_ME = 'pdags_remember_me';
export const AUTH_TOKEN = 'pdags_auth_token';
export const CHECK_NEW_ORDER_INTERVAL = 30000;
export const CRITERIA_MAX = 'Maior';
export const CRITERIA_AVERAGE = 'Média';
export const CRITERIO_BAIRRO = 'Bairro';
export const CRITERIO_DISTANCIA = 'Distância';
export const CRITERIO_RAIO = 'Raio';
export const CURRENT_SCHEDULED_ORDER_ID = 'currentScheduledOrderId';
export const CURRENT_ORDER_ID = 'currentOrderId';
export const CURRENT_STORE_CODE = 'pdags_current_store_code';
export const CURRENT_STORE_ID = 'pdags_current_store_id';
export const DEFAULT_ITEM_LIMIT = 100;
export const DISTANCE_MODE_SPHERICAL = 'spherical';
export const DISTANCE_MODE_HAVERSINE = 'haversine';
export const DISTANCE_MODE_RECTANGLE = 'rectangle';
export const ENTRIES_BY_PAGE = '35';
export const FEEDBACK = 'pdags_feedback_message';
export const FREE_VALUE = 'Grátis';
export const MIN_INTERVAL_RELOAD = 60000;
export const DELAY_NOTIFICATION = 300;
export const DELAY_TRANSITION_REDIRECT = 2000;
export const IMPROVMENT_SUGGESTION_LIST = [
    'Atendimento',
    'Embalagem',
    'Item(ns) Errado(s)',
    'Postura do Entregador',
    'Qualidade do Produto',
    'Tempo de Entrega'
];
export const INTERNAL_HOSTS = [
    'localhost',
    '192.168.1.108',
    '192.168.1.109',
    '192.168.1.110'
];
export const ITEMS_BY_SCROLLING =  '50';
export const MAIN_ADDRESS_DESCRIPTION =  'Meu endereço principal';
export const MIN_ALLOWED_AGE = 16;
export const MAX_ALLOWED_AGE = 90;
export const ONLINE_PAYMENT_OPTIONS =  {
    credito: 'C',
    debito: 'D',
    creditoEDebito: 'CD'
};
export const PAYMENT_TYPE_CREDIT = 'CreditCard';
export const PAYMENT_TYPE_DEBIT = 'DebitCard';
export const PUBLIC_ENDPOINTS = [
    '/home',
    '/backoffice',
    '/carrinho',
    '/catalogo',
    '/concentre',
    '/configuracoes',
    '/contas',
    '/empresas',
    '/empresas/conta',
    '/empresa/pedidos',
    '/fcm-token',
    '/ifood',
    '/integration',
    '/mensagem',
    '/monitoring',
    '/opcoes-pagamento-cartao',
    '/personalizacoes',
    '/pagamentos',
    '/portal',
    '/printer',
    '/produtos',
    '/promocoes',
    '/public',
    '/socket',
    '/store',
    '/tokens/revoke',
    '/ui',
    '/ultimo-acesso',
];
export const PUBLIC_ENDPOINTS_EXCEPT = [ '/cartoes'];
export const REDIRECT_DELAY =  2000;
export const PIZZA_CALC_CRITERA_AVERAGE: DataOption = {
    label: 'Média',
    value: 'MEDIA'
};
export const PIZZA_CALC_CRITERA_MAX: DataOption = {
    label: 'Maior',
    value: 'MAIOR'
};
export const PROFILE_ADMINISTRATOR_ID =  1;
export const PROFILE_CUSTOMER_ID =  2;
export const PROFILE_COMPANY_ID =  3;
export const PROFILE_COLABORATOR_ID =  4;
export const REF_SIZE_BROTO =  'Broto';
export const REF_SIZE_MEDIA =  'Média';
export const REF_SIZE_MEDIO =  'Médio';
export const REF_SIZE_GRANDE =  'Grande';
export const REF_SIZE_GIGANTE =  'Gigante';
export const REF_SIZE_PEQUENO =  'Pequeno';
export const REQUEST_ORDER_TIME =  '2';
export const REGEX_ALLOWED_PICTURE_EXTENSION =  /\.(jpe?g|png|gif|JPE?G|PNG|GIF)/g;
export const SCROLLING_DELAY  = 500;
export const SELECTED_PROMOTION_BASKET_ITEM  = 'selected_promotion_basket_item';
/** @deprecated */
export const SHOPPING_CART_LAST_UPDATE  = 'shopping_cart_last_update';
export const SHOPPING_CART_MAX_HOURS  = 12;
export const TABLE_NUMBER  = 'table_number';
export const VENDOR  = 'pdags_vendor';
/** @deprecated */
export const WEB_TOKEN = 'pdags_web_token';
