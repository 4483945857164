import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';

import { CURRENT_STORE_CODE, CURRENT_STORE_ID, ENTRIES_BY_PAGE } from 'src/app/shared/consts/domains';
import { OrderOptions } from 'src/app/shared/consts/order-options';
import { CatalogoDePersonalizacoes } from 'src/app/shared/implements/catalogo-de-personalizacoes.ts';
import { ResultSet } from 'src/app/shared/implements/result-set';
import { CategoriaDoProduto } from 'src/app/shared/models/categoria-do-produto';
import { ConfiguracaoDePizzaria } from 'src/app/shared/models/configuracao-de-pizzaria';
import { Empresa } from 'src/app/shared/models/empresa';
import { EmpresaConfiguracao } from 'src/app/shared/models/empresa-configuracao';
import { EmpresaProdutoDestaque } from 'src/app/shared/models/empresa-produto-destaque';
import { HorarioDeFuncionamento } from 'src/app/shared/models/horario-funcionamento';
import { Produto } from 'src/app/shared/models/produto';
import { Promocao } from 'src/app/shared/models/promocao';
import { PromocaoBrinde } from 'src/app/shared/models/promocao-brinde';
import { PromocaoRegraDeCompra } from 'src/app/shared/models/promocao-regra-compra';
import { StatusDaLoja } from 'src/app/shared/models/status-da-loja';
import { StatusDoPedido } from 'src/app/shared/models/status-do-pedido';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class StoreService {

    private readonly criteriaSeparator = '@@';
    private readonly api = environment.apiUrl;
    private readonly endpoint = 'store';

    constructor(
        private http: HttpClient,
        private storageService: StorageService
    ) {}

    /**
     * Obtem o estado da categoria do produto indicando se ela está ativa ou não
     * @param categoryId number
     * @returns Observable<boolean>
     */
    getCategoryState(categoryId: number) {
        return this.http.get<boolean>(`${this.api}/${this.endpoint}/catalog/category/${categoryId}/state`)
            .pipe(take(1));
    }

    getCompanyId() {
        return this.storageService.secureSessionStorage.getItem(CURRENT_STORE_CODE);
    }

    getStoreId() {
        return this.storageService.secureSessionStorage.getItem(CURRENT_STORE_ID);
    }

    /**
     * Obtem o cadastro de um produto de uma respectiva loja.
     * @param companyId number
     * @param productId number
     * @returns Observable<Produto>
     */
    findProductById(productId: number) {
        return this.http.get<Produto>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/product/${productId}`
        ).pipe(first());
    }

    /**
     * Obtem o cadastro da empresa pelo ID da loja virtual.
     * @returns Observable<Empresa>
     */
    getCompanyByStoreId() {
        return this.http.get<Empresa>(`${this.api}/${this.endpoint}/${this.getStoreId()}`)
            .pipe(first());
    }

    /**
     * Obtem a configuração de uma respectiva empresa.
     * @param configName string
     * @returns Observable<EmpresaConfiguracao>
     */
    getConfig(configName: string) {
        return this.http.get<EmpresaConfiguracao>(`${this.api}/${this.endpoint}/${this.getCompanyId()}/config/${configName}`)
            .pipe(first());
    }

    /**
     * Lista todas as personalizações de um respectivo produto.
     * @param productId number
     * @returns Observable<CatalogoDePersonalizacoes>
     */
    getCustomizationCatalogByProduct(productId: number) {
        return this.http.get<CatalogoDePersonalizacoes>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/product/${productId}/customizations`
        ).pipe(first());
    }

    /**
     * Obtem se o telefone do respectivo cliente está em blacklist.
     * @param telephone string
     * @returns Observable<boolean>
     */
    getCustomerBlacklistState(telephone: string) {
        return this.http.get<boolean>(
            `${this.api}/${this.endpoint}/${this.getCompanyId()}/customer/blacklist-state/${telephone}`
        ).pipe(first());
    }
    /**
     * Obtem os horários de funcionamento da empresa.
     * @returns Observable<HorarioDeFuncionamento[]>
     */
    getOpeningHours(): Observable<HorarioDeFuncionamento[]> {
        return this.http.get<HorarioDeFuncionamento[]>(`${this.api}/${this.endpoint}/${this.getCompanyId()}/opening-hours`)
            .pipe(take(1));
    }

    /**
     * Obtem o estado atual de um respectivo pedido.
     * @param orderId number
     * @returns Observable
     */
    getOrderState(orderId: number) {
        return this.http.get<StatusDoPedido>(`${this.api}/${this.endpoint}/order/${orderId}`)
            .pipe(first());
    }

    /**
     * Obtem as configurações de pizzaria de uma respectiva empresa.
     * @returns Observable<ConfiguracaoDePizzaria>
     */
    getPizzaConfig() {
        return this.http.get<ConfiguracaoDePizzaria>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/configuration/pizza`
        ).pipe(first());
    }


    /**
     * Obtem as regras de compra de um respectivo combo.
     * @param promotionId number
     * @returns Observable<PromocaoRegraDeCompra[]>
     */
    getPromotionRules(promotionId: number) {
        return this.http.get<PromocaoRegraDeCompra[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/promotion/${promotionId}/rules`
        ).pipe(first());
    }

    /**
     * Obtem o estado atual de um respectivo pedido.
     * @param orderId number
     * @returns Observable
     */
    getScheduledOrderState(orderId: number) {
        return this.http.get<StatusDoPedido>(`${this.api}/${this.endpoint}/order/scheduled/${orderId}`)
            .pipe(first());
    }

    /**
     * Obtem o estado de abertura da loja de uma respectiva empresa.
     * @returns Observable<StatusDaLoja>
     */
    getWorkingState() {
        return this.http.get<StatusDaLoja>(`${this.api}/${this.endpoint}/${this.getCompanyId()}/work/state`)
            .pipe(first());
    }

    /**
     * * Obtem o estado de abertura da loja de uma respectiva empresa com a situação do Pedidio.
     * @param orderId number
     * @returns Observable<StatusDaLoja>
     */
    getWorkingAndOrderState(orderId: number) {
        return this.http.get<boolean>(
            `${this.api}/${this.endpoint}/${this.getCompanyId()}/work/state/order/${orderId}`
        ).pipe(first());
    }

    /**
     * Lista todas as categorias ativas de uma respectiva empresa.
     * @returns Observable<CategoriaDoProduto[]>
     */
    listCategories() {
        return this.http.get<CategoriaDoProduto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/categories`
        ).pipe(first());
    }

    /**
     * Lista todos os produtos do catálogo disponíveis.
     * @param categoryId number
     * @returns Observable<Produto[]>
     */
    listCatalogProductsByCategory(categoryId: number) {
        return this.http.get<Produto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/products/category/${categoryId}`
        ).pipe(first());
    }
    /**
     * Lista todas as categorias em destaque de uma respectiva empresa.
     * @returns Observable<CategoriaDoProduto[]>
     */
    listSpotlightCategories() {
        return this.http.get<CategoriaDoProduto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/spotlight/categories`
        ).pipe(first());
    }

    /**
     * Lista todos os produtos em destaque de uma respectiva empresa.
     * @returns Observable<EmpresaProdutoDestaque>
     */
    listSpotlightProducts() {
        return this.http.get<EmpresaProdutoDestaque[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/spotlight/products`
        ).pipe(first());
    }

    /**
     * Lista todas as promoções em destaque de uma respectiva empresa.
     * @returns Observable<Promocao[]>
     */
    listSpotlightPromotion() {
        return this.http.get<Promocao[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/spotlight/promotions`
        ).pipe(first());
    }

    /**
     * Lista as subcategorias de uma respectiva categoria de produto.
     * @param categoryId number
     * @returns Observable<CategoriaDoProduto[]>
     */
    listSubcategories(categoryId: number)  {
        return this.http.get<CategoriaDoProduto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/category/${categoryId}/subcategories`
        ).pipe(first());
    }

    /**
     * Lista todos os brindes de uma respectiva regra de compra.
     * @param ruleId number
     * @returns Observable<PromocaoBrinde[]>
     */
    listPromotionGifts(ruleId: Number): Observable<PromocaoBrinde[]> {
        return this.http.get<PromocaoBrinde[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/promotion/rule/${ruleId}/list/gifts`
        ).pipe(take(1));
    }

    /**
     * Lista todos os produtos em destaque de uma respectiva empresa de forma aleatória.
     * @returns Observable<Produto[]>
     */
    listTempSpotlightProducts() {
        return this.http.get<Produto[]>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/spotlight/products/temp`
        ).pipe(first());
    }
    
    /**
     * Identifica se a loja está aberta ou fechada
     * @returns Observable<boolean>
     */
    storeIsOpened(): Observable<boolean> {
        return this.http.get<boolean>(`${this.api}/${this.endpoint}/${this.getCompanyId()}/is-opened`)
            .pipe(first());
    }

    /**
     * Lista uma página de resultados de produtos com base nos filtros aplicados.
     * @param criterias string[]
     * @param pageNumber number
     * @param orderBy string[]
     * @param sortDirection string
     * @param entriesByPage string
     * @returns Observable<ResultSet<Produto>>
     */
    paginateCatalogProduct(criterias: string[], pageNumber: number, orderBy?: string[],
        sortDirection?: string, entriesByPage?: string) {
        const searchCriteria = [];
        searchCriteria.push('empresaId:' + this.getCompanyId());
        searchCriteria.push('categoriaAtiva:' + 1);
        searchCriteria.push('excluido:' + 0);

        if (criterias.length > 0)
            criterias.map(criteria => searchCriteria.push(criteria));
        
        const params = new HttpParams()
            .append('search', searchCriteria.join(this.criteriaSeparator))
            .append('numeroPagina', pageNumber > 0 ? `${pageNumber - 1}` : '0')
            .append('sortBy', orderBy ? orderBy.join(',') : '')
            .append('sortDirection', sortDirection || OrderOptions.ASCENDING)
            .append('entriesByPage', entriesByPage || ENTRIES_BY_PAGE);

        return this.http.get<ResultSet<Produto>>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/products/filter`,
            { params }
        ).pipe(first());
    }
    
    /**
     * Lista uma página de resultados de brindes de promoção com base nos filtros aplicados.
     * @param criterias string[]
     * @param pageNumber number
     * @param orderBy string[]
     * @param sortDirection string
     * @param entriesByPage string
     * @returns Observable<ResultSet<PromocaoBrinde>>
     */
    paginateComboGifts(ruleId: number, criterias: string[], pageNumber: number, entriesByPage?: string) {
        const searchCriteria = [];
        searchCriteria.push('empresaId:' + this.getCompanyId());
        searchCriteria.push('categoriaAtiva:' + 1);
        searchCriteria.push('excluido:' + 0);

        if (criterias.length > 0)
            criterias.map(criteria => searchCriteria.push(criteria));
        
        const params = new HttpParams()
            .append('search', searchCriteria.join(this.criteriaSeparator))
            .append('numeroPagina', pageNumber > 0 ? `${pageNumber - 1}` : '0')
            .append('entriesByPage', entriesByPage || ENTRIES_BY_PAGE);

        return this.http.get<ResultSet<PromocaoBrinde>>(
            `${this.api}/${this.endpoint}/catalog/${this.getCompanyId()}/promotion/rule/${ruleId}/paginate/gifts`,
            { params }
        ).pipe(first());
    }

}
