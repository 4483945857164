import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ENTRIES_BY_PAGE } from 'src/app/shared/consts/domains';
import { OrderOptions } from 'src/app/shared/consts/order-options';
import { ResultSet } from 'src/app/shared/implements/result-set';
import { Resource } from 'src/app/shared/models/resource';
import { environment } from 'src/environments/environment';

export class ResourceService<T extends Resource> {

    companyId: number;

    protected api = environment.apiUrl;
    protected searchCriteria: string[] = [];
    protected readonly criteriaSeparator = '@@';

    constructor(protected http: HttpClient, protected endpoint: string) {
    }

    /**
     * Exclui um recurso do banco de dados.
     * @param id number
     */
    public delete(id: number) {
        return this
            .http.delete(`${this.api}/${this.endpoint}/${this.companyId}/${id}`)
            .pipe(take(1));
    }

   /**
    * Aplica uma consulta com páginação a partir dos filtros informados.
    * @param searchCriteria string[]
    * @param page number
    * @param sortBy string[]
    * @param orderDirection string
    * @param entriesByPage string
    */
    filter(
        searchCriteria: string[], page: number, sortBy?: string[], orderDirection?: string,
        entriesByPage?: string): Observable<ResultSet<T>> {
        this.searchCriteria = [];
        this.searchCriteria.push('empresaId:' + this.companyId);

        if (searchCriteria.length > 0) {
            searchCriteria.map(criteria => this.searchCriteria.push(criteria));
        }

        const searchParams = new HttpParams()
            .append('search', this.searchCriteria.join(this.criteriaSeparator))
            .append('numeroPagina', page > 0 ? `${page - 1}` : '0')
            .append('sortBy', sortBy ? sortBy.join(',') : '')
            .append('sortDirection', orderDirection || OrderOptions.ASCENDING)
            .append('entriesByPage', entriesByPage || ENTRIES_BY_PAGE);
        return this.http.get<ResultSet<T>>(
            `${this.api}/${this.endpoint}/${this.companyId}/filter`, { params: searchParams }).pipe(take(1));
    }

    /**
     * Obtem o registro de um recurso a partir do seu respectivo ID.
     * @param id number
     */
    public get(id: number): Observable<T> {
        return this
            .http.get<T>(`${this.api}/${this.endpoint}/${this.companyId}/${id}`)
            .pipe(take(1));
    }

    /**
     * Lista todos os registros de um respectivo recurso.
     */
    public list(): Observable<T[]> {
        return this
            .http.get<T[]>(`${this.api}/${this.endpoint}/${this.companyId}/`)
            .pipe(take(1));
    }

    public save(resource: T) {
        if (resource.id) {
            return this.update(resource);
        } else {
            return this.create(resource);
        }
    }

    /**
     * Salva um recurso no banco de dados
     * @param resource T
     */
    private create(resource: T): Observable<T> {
        return this.http
            .post<T>(`${this.api}/${this.endpoint}/${this.companyId}/`, resource)
            .pipe(take(1));
    }

    /**
     * Atualiza um recurso no banco de dados.
     * @param resource T
     */
    private update(resource: T): Observable<T> {
        return this.http
            .put<T>(`${this.api}/${this.endpoint}/${this.companyId}/`, resource)
            .pipe(take(1));
    }

}
