import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';
import * as SecureStorage from 'secure-web-storage';

const SECRET_KEY = 'pdags_key';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    public secureLocalStorage = new SecureStorage(localStorage, {
        hash: (key): any => {
            key = CryptoJS.SHA256(key, SECRET_KEY);

            return key.toString();
        },
        encrypt: (data: any) => {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);

            return data;
        },
        decrypt: (data: any) => {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            data = data.toString(CryptoJS.enc.Utf8);
            return data;
        }
    });

    public secureSessionStorage = new SecureStorage(sessionStorage, {
        hash: (key): any => {
            key = CryptoJS.SHA256(key, SECRET_KEY);

            return key.toString();
        },
        encrypt: (data: any) => {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);

            return data;
        },
        decrypt: (data: any) => {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            data = data.toString(CryptoJS.enc.Utf8);
            return data;
        }
    });
}