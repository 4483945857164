class DiaDaSemana {
    id: number;
    prefixo: string;
    descricao: string;

    constructor (id: number, prefixo: string, descricao: string) {
        this.id = id;
        this.prefixo = prefixo;
        this.descricao = descricao;
    }
}

export const Semana = {
    TODOS_OS_DIAS: new DiaDaSemana(0, 'TODOS_OS_DIAS', 'Todos os dias'),
    DOMINGO: new DiaDaSemana(1, 'DOMINGO', 'Domingo'),
    SEGUNDA_FEIRA: new DiaDaSemana(2, 'SEGUNDA_FEIRA', 'Segunda'),
    TERCA_FEIRA: new DiaDaSemana(3, 'TERCA_FEIRA', 'Terça'),
    QUARTA_FEIRA: new DiaDaSemana(4, 'QUARTA_FEIRA', 'Quarta'),
    QUINTA_FEIRA: new DiaDaSemana(5, 'QUINTA_FEIRA', 'Quinta'),
    SEXTA_FEIRA: new DiaDaSemana(6, 'SEXTA_FEIRA', 'Sexta'),
    SABADO: new DiaDaSemana(7, 'SABADO', 'Sábado')
};
