import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { CompanyResolverGuard } from './shared/guards/company-resolver.guard';

const COMPANY_RESOLVER = {
    company: CompanyResolverGuard
};

const routes: Routes = [
    {
        path: 'cashback-redirect',
        loadChildren: () => import('./cashback-redirect/cashback-redirect.module').then(
            m => m.CashbackRedirectPageModule
        )
    },
    {
        path: '404',
        loadChildren: () => import('./store-not-found/store-not-found.module').then(
            m => m.StoreNotFoundPageModule
        )
    },
    {
        path: 'unavaliable-store',
        loadChildren: () => import('./unavaliable-store/unavaliable-store.module').then(
            m => m.UnavaliableStorePageModule
        )
    },
    {
        path: 'privacy',
        loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule),
        pathMatch: 'full'
    },
    {
        path: ':storeId',
        loadChildren: () => import('./store/store.module').then(
            m => m.StorePageModule
        ),
        resolve: COMPANY_RESOLVER
    },
    {
        path: ':storeId/store',
        loadChildren: () => import('./store/store.module').then(m => m.StorePageModule),
        resolve: COMPANY_RESOLVER
    },
    {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full'
    },

];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: NoPreloading,
            initialNavigation: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
