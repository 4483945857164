import { Injectable } from '@angular/core';

import { NullUtil } from 'src/app/shared/utils/null.util';
import { NavData } from 'src/app/shared/implements/nav-data';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private datasource: NavData[] = [];

    constructor() { }

    /**
     * Adiciona um objeto de dados na lista de extras. Caso a chave já exista, o valor 
     * correspondente será atualizado.
     * @param data NavData
     */
    addData(key: string, value: any) {
        if (this.hasData(key)) {
            this.updateData(key, value);
            return;
        }

        const data = {} as NavData;
        data.key = key;
        data.value = value;
        this.datasource.push(data);
    }

    /**
     * Atualiza um dado extra a partir de sua chave e valor.
     * @param key string
     * @param value any
     */
    updateData(key: string, value: any) {
        if (this.datasource.some(data => data.key === key)) {
            this.datasource.forEach(data => {
                if (data.key === key) {
                    data.value = value;
                }
            });
        }
    }

    /**
     * Obtem um dado extra a partir da chave.
     * @param key string
     * @returns any
     */
    getData(key: string): any {
        return this.datasource.filter(data => data.key === key)[0]?.value || null;
    }

    /**
     * Lista todas as entradas de dados extras..
     */
    listData() {
        return this.datasource;
    }

    /**
     * Remove um dadoe extra a partir de uma chave.
     * @param key string
     */
    removeData(key: string) {
        if (this.datasource.some(data => data.key === key)) {
            this.datasource.map((data, idx) => {
                return {
                    index: idx,
                    value: data.key
                };
            }).map(item => {
                if (item.value === key) {
                    this.datasource.splice(item.index, 1);
                }
            });
        }
    }

    /**
     * Reinicia a lista dos dados extras
     */
    resetData() {
        this.datasource = [];
    }

    /**
     * Testa se existe valor de uma respectiva chave na lista de extras.
     * @param key string
     * @returns boolean
     */
    hasData(key: string): boolean {
        return this.datasource.some(data => data.key === key && NullUtil.isSet(data.value));
    }

}
