export class NumberUtil {

    /**
     * Ordena um array numérico em ordem crescente.
     * @param numberArray number[]
     */
    static ascendingOrder(numberArray: number[]) {
        return numberArray.sort((a, b) => a - b);
    }

    /**
     * Converte uma string decimal com posições fixas
     * @param value string
     * @param toFix number
     */
    static fixDecimalPlaces(value: string, toFix: number) {
        return parseFloat(value).toFixed(toFix);
    }

    /**
     * Testa se um respectivo valor é numérico,
     * @param value value
     */
    static isNumber(value: string) {
        const regex = new RegExp(/^[0-9]*$/g);

        return regex.test(value);
    }

    /**
     * Verifica se o número não é nulo (ou indefinido) e maior que zero.
     * @param value number
     */
    static isNotNullOrZero(value: number) {
        return value && value > 0;
    }

    /**
     * Converte uma string em valor decimal.
     * @param value string
     */
    static parseDecimal(value: string, precision: number = 2): number {
        value = value.replace(/\D/g, '');
        value = value.substr(0, value.length - precision) + '.' + value.substr(value.length - precision, value.length);

        return parseFloat(value);
    }

    /**
     * Converte uma string decimal em float.
     * @param value string
     * @param toFix number
     */
    static parseNumber(value: string): number {
        if (!value) {
            return 0;
        }

        return parseFloat(value.replace(/\./g, '').replace(/\,/g, '.'));
    }

    /**
     * Verifica se um intervalo numérico é válido
     * @param firstValue number
     * @param lastValue number
     */
    static isValidRange(firstValue: number, lastValue: number) {
        return firstValue < lastValue;
    }

}
